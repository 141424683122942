.hero {
  height: 50vh;
  background-image: url("../../../Images/hero.jpg");
  background-position: bottom;
  background-size: cover;
}

.logo {
  color: white;
  font-family: "Street Cred";
  font-size: 15rem;
  letter-spacing: 1rem;
}

.small {
  font-size: 100px;
}

.glitch {
  position: relative;

  &::before,
  &::after {
    content: "nzt";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    color: white;
  }

  &::before {
    left: 3px;
    text-shadow: blue 2px 0;
    animation: glitch1 1s infinite alternate-reverse;
  }
  &::after {
    left: -3px;
    text-shadow: rgba(255, 0, 0, 0.8) -2px 0;
    animation: glitch2 2s infinite alternate-reverse;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .hero {
    height: 40vh;
  }

  .logo {
    font-size: 8rem;
  }
}
