.footer-wrapper {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    background-color: #424242;
    height: 200px;
    overflow: hidden;

    a {
        color: white;
        &:hover {
            color: lightblue;
        }
    }


    .mapa {
        clip-path: circle(65.2% at 35% 48%);
        position: relative;

        img {
            object-fit: cover;
            width: 100%;
            position: absolute;
            top: 0px;
            height: 100%;
        }


    }

    .footer-data {
        text-align: left;
        color: white;
        margin: auto 0px;

        h3{
            margin: 20px;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: .1rem;

            span{
                margin-right: 8px;
            }
        }
    }
}

footer {
    position: relative;

    .sign {
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-size: .7rem;
        letter-spacing: .05rem;

        a{
            color: #9b9b9b;

            &:hover {
                b {
                    color: white;
                }
            }
        }
    }
}


@media (min-width: 320px) and (max-width: 390px) {
    .footer-wrapper {
        display: flex;
        .mapa {
            display: none;
        }
    }
}

@media (min-width: 391px) and (max-width: 500px) {
    .footer-wrapper {
        display: flex;
        .mapa {
            display: none;
        }

        .footer-data {
            text-align: center;
            color: white;
            margin: auto;

            h3{
                margin: 20px;
                font-size: 1.3rem;
                font-weight: 500;
                letter-spacing: .1rem;

                span{
                    margin-right: 5px;
                }
            }
        }
    }
}

@media (min-width: 501px) and (max-width: 700px) {
    .footer-wrapper {
        display: flex;
        .mapa {
            display: none;
        }

        .footer-data {
            text-align: center;
            color: white;
            margin: auto;

            h3{
                margin: 20px;
                font-size: 1.3rem;
                font-weight: 500;
                letter-spacing: .1rem;

                span{
                    margin-right: 5px;
                }
            }
        }
    }
}

@media (min-width: 701px) and (max-width: 1000px) {
    .footer-wrapper {
        grid-template-columns: 1fr 1fr;

        .mapa {
            img {
                top: 0;
                height: 100%;
            }
        }
    }
}