.form-container {
    .form {
        max-width: 500px;
        margin: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
            font-weight: 700;
        }
        input {
            padding: 8px;
        }
        button {
            padding: 5px;
            background-color: blue;
            color: white;
            font-weight: 700;

            &:hover {
                background-color:rgb(112, 198, 226);
                color: black;
                cursor: pointer;
            }
        }
    }
}