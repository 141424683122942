.player-container {
  padding: 50px;

  .botonVolver {
    margin-bottom: 20px;
    text-align: center;

    a {
      color: black;

      span {
        margin-right: 2px;
        display: block;
      }

      &:hover {
        color: red;
        span {
          margin-right: 5px;
        }
      }
    }
  }
  .player-chat {
    display: grid;
    grid-template-columns: 1fr 300px;

    .player-wrapper {
      position: relative;
      padding-top: 100%;
      &.res-16by9 {
        padding-top: 56.25%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .chat-wrapper {
      width: 300px;
      text-align: center;
      padding: 5px;
    }
  }
}

.noStreamContainer {
  background-image: url("../../../Images/hero.jpg");
  background-position: bottom;
  background-size: cover;
  height: 250px;
  margin-top: 60px;
}

.form {
  max-width: 250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .button {
    padding: 5px;
    background-color: transparent;
    border-radius: 5px;
    font-weight: 600;
  }

  .input {
    padding: 10px;
    margin-bottom: 10px;
  }

  .errorMsg {
    color: red;
    font-size: small;
    margin-top: 10px;
  }
}

.goBackButtonContainer {
  margin-left: 10px;
  margin-top: 20px;

  .goBackButton {
    text-decoration: none;
    font-size: 13px;
    border: 1px solid #000;
    padding: 5px;
  }
}

@media (max-width: 800px) {
  .player-container {
    .player-chat {
      display: block;
      .chat-wrapper {
        display: none;
      }
    }
  }
}
