$colors: #040abf, #0644d6, #0675cc;

.services-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 30px 50px;
  position: relative;
  padding: 2rem;

  .img-service {
    height: 300px;
    width: 100%;
    position: relative;

    &.flecha {
      display: block;
      transition: all ease-in-out 150ms;

      &.right {
        &:hover {
          @include colorShadow(
            $colors,
            $sizeX: 10px,
            $sizeY: 8px,
            $directionX: "right"
          );
          .flecha-inner {
            transform: translate(5px);
            filter: sepia(0.5);
          }
        }
      }
      &.left {
        &:hover {
          @include colorShadow(
            $colors,
            $sizeX: 10px,
            $sizeY: 8px,
            $directionX: "left"
          );
          .flecha-inner {
            transform: translate(5px);
            filter: sepia(0.5);
          }
        }
      }

      .flecha-inner {
        transition: all ease-in-out 150ms;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-height: 300px;
        }
        &.right {
          clip-path: polygon(
            75% 0%,
            100% 50%,
            75% 100%,
            0% 100%,
            25% 50%,
            0% 0%
          );
        }
        &.left {
          clip-path: polygon(
            100% 0%,
            75% 50%,
            100% 100%,
            25% 100%,
            0% 50%,
            25% 0%
          );
        }
      }
    }
  }

  .data-service {
    padding: 1rem;
    color: black;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-size: 3rem;
      font-weight: 600;
    }
    p {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 500px) {
  .services-wrapper {
    display: flex;
    flex-direction: column;

    .img-service {
      height: 150px;
      box-shadow: red 2px 2px, blue -2px -2px;

      &.flecha {
        .flecha-inner {
          &.right {
            clip-path: none;
          }
          &.left {
            clip-path: none;
          }
        }
      }
      &.img-streaming {
        order: 2;
      }
      &.img-recording {
        order: 4;
      }
      &.img-postpo {
        order: 6;
      }
    }

    .data-service {
      color: black;
      background-size: cover;
      text-shadow: black 1px;
      &.streaming {
        order: 1;
      }
      &.recording {
        order: 3;
      }
      &.postpo {
        order: 5;
      }
      h2 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
}

@media (min-width: 501px) and (max-width: 700px) {
  .services-wrapper {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;

    .img-service {
      &.flecha {
        .flecha-inner {
          &.right {
            clip-path: none;
          }
          &.left {
            clip-path: none;
          }
        }
      }
    }
    .data-service {
      background-color: #424242;
      color: white;
    }
  }
}
