.about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 50px;

    .esteban-data {
        color: white;


        h2 {
            font-size: 4rem;
            font-weight: 600;
            letter-spacing: .2rem;

            span {
                color: #0675CC;
                transition: ease-in-out;
                animation: glitch2 1s infinite;
            }
        }

        p {
            font-size: 1rem;
            letter-spacing: .05rem;

            span {
                font-weight: 600;

                &:hover {
                    color: #0675CC;
                }
            }
        }

        .partners {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 40px;



            .partner-wrapper {
                height: 60px;
                width: 60px;

                img {
                    object-fit: cover;
                    width: 100%;
                    transition: ease-in-out 350ms;
                    filter: grayscale(.8);
                    &:hover {
                        filter: grayscale(0);
                        cursor: pointer;
                    }
                }
            }
        }

    }

    .esteban-img {
        text-align: center;
        margin: 5px auto;

        &:hover{
            animation: carucha 45ms infinite alternate-reverse;
        }
    }
}


@media (max-width: 500px) {
    .about {
        display: flex;
        flex-direction: column;

        .esteban-data {
            h2 {
                font-size: 3rem;
            }
            p {
                font-size: .8rem;
            }

            .partners {
                margin: 50px 0;
            }
        }

        .esteban-img {
            animation: carucha 45ms infinite alternate-reverse;
        }
    }
}

@media (min-width: 501px) and (max-width: 700px) {
    .about {
        display: flex;
        flex-direction: column;
        position: relative;

        .esteban-data {
            h2 {
                font-size: 3rem;
                margin-bottom: 75px;
                span {
                    animation: none;
                }
            }
            p {
                font-size: 1rem;
            }

            .partners {
                margin: 50px 0;
            }
        }

        .esteban-img {
            animation: carucha 45ms infinite alternate-reverse;
            width: 120;
            position: absolute;
            right: 30px;
            top: 20px;
        }
    }
}

@media (min-width: 701px) and (max-width: 1000px) {
    .about {
        display: flex;
        flex-direction: column;
        position: relative;

        .esteban-data {
            h2 {
                margin-bottom: 50px;
                span {
                    animation: none;
                }
            }
        }

        .esteban-img {
            animation: carucha 45ms infinite alternate-reverse;
            width: 140;
            position: absolute;
            right: 75px;
            top: 20px;
        }
    }
}