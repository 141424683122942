@mixin colorShadow($colors: "", $sizeX: 0px, $sizeY: 0px, $directionX: "left", $directionY: "bottom") {
  $offsetX: $sizeX;
  $offsetY: $sizeY;
    @if $directionX != "left" {
      $offsetX: $sizeX * -1;
    }
    @if $directionY != "bottom" {
      $offsetY: $sizeY * -1;
    }

    $shadows: '';
    @for $i from 1 through length($colors) {
      $color: nth($colors, $i);
      $shadows: $shadows + 'drop-shadow(' + $offsetX + ' ' + $offsetY + ' 0px ' + $color + ')';
      @if $i < length($colors) {
        $shadows: $shadows + ' ';
      } @else {
        $shadows: $shadows + ';';
      }
    }
    filter: unquote($shadows);
  }